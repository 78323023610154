<template>
    <div class="card tile is-child">
        <div class="card-image">
            <figure class="image is-4by3">
                <img :src="getImgUrl(image)"/>
            </figure>
        </div>
        <div class="card-content">
            <div class="media">

                <div class="media-content">
                    <p class="title is-4">{{title}}</p>
                </div>
            </div>

            <div class="content has-text-left">

                <p v-on:click="awesome=true" v-if="!awesome">
                    {{beschreibung | truncate(120, '...')}}
                    <a>Weiterlesen</a>

                </p>
                <p v-if="awesome" v-on:click="awesome=false">
                    <span v-html="beschreibung"/>
                </p>


                <p class="title is-5">Zielgruppe</p>
                <p class="subtitle is-6">{{zielgruppe}}</p>

                <div style="margin-top:10px"></div>
                <p class="title is-5">Termine</p>
                <p class="subtitle is-6">{{termine}}</p>

                <div class="media" style="margin-top:40px;">
                    <div class="media-left">
                        <figure class="image is-64x64">
                            <img class="is-rounded" :src="getAnsprechpartnerImg(ansprechpartnerBild)">
                        </figure>
                    </div>
                    <div class="media-content">
                        <p class="title is-4">{{ansprechpartner}}</p>
                        <p class="subtitle">
                            <a class="button is-link is-size-7-mobile"  v-on:click="showKontaktformular(!mailForm)">E-Mail schreiben</a>
                        </p>
                    </div>
                </div>
                <Kontaktformular :empfaenger=ansprechpartnerMail :showKontaktFormular=mailForm
                                 @show-kontaktformular="showKontaktformular"/>

            </div>
        </div>
    </div>
</template>

<script>
import Kontaktformular from '@/components/Kontaktformular.vue'

export default {
        name: 'GruppeCard',
        components: {Kontaktformular},
        props: {
            title: String,
            subtitle: String,
            image: String,
            beschreibung: String,
            zielgruppe: String,
            termine: String,
            ansprechpartner: String,
            ansprechpartnerBild: String,
            ansprechpartnerMail: String,
        },
        data() {
            return {
                isOpen: false,
                awesome: false,
                mailForm: false
            }
        },
        methods: {
            getImgUrl(pic) {
                return require('../assets/' + pic)
            },
            getAnsprechpartnerImg(pic) {
                return require('../assets/ansprechpartner/' + pic);
            },
            showKontaktformular(showMailmForm) {
                this.mailForm = showMailmForm;
            },
        }

    }
</script>